import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'

import { ReleaseListsFragment, UserReleaseListsFragment, ItemType, ListInfoFragment } from '../../api/types'
import { extractNodes } from '../../lib/nodes'
import { Section } from '../../lib/components/section'
import { Button } from '../../lib/components/buttons'
import { Link } from '../../lib/components/link'
import { ViewMore } from '../view-more'
import { useLogin, login } from '../../lib/login'
import { useShortcut } from '../../lib/components/shortcuts'
import { a, l } from '../../lib/keycodes'
import { useConfirm, Confirmation } from '../../lib/components/modal'
import { Loading } from '../../lib/components/loading'
import { useInteraction } from '../../lib/components/analytics'
import { useAddItemToListModal } from '../../modals/add-item-to-list'

import { useRemoveItemFromListMutation } from '../../mutations/remove-item-from-list'

import css from './styles.css'

// TODO: ReleaseListsFragment should just be ListsFragment
type ListsData = NonNullable<ReleaseListsFragment['lists']>

type Props = {
    discogsId: number
    user: UserReleaseListsFragment | null | undefined
    itemType: ItemType
    lists?: ListsData | null | undefined
}

export function CuratedLists(props: Props): ReactElement {
    const { discogsId, user, itemType, lists } = props

    const { loggedIn } = useLogin()
    const addItemToList = useAddItemToListModal()
    const confirm = useConfirm()
    const track = useInteraction()
    const remove = useRemoveItemFromListMutation()

    const actionName = <Trans>Add Master to List</Trans>

    function addToList(ignoreIfLoggedOut: boolean = false) {
        if (!loggedIn && !ignoreIfLoggedOut) {
            login()
            return
        }
        track('Filter - Add to List')
        addItemToList.open(itemType, discogsId)
    }

    useShortcut([a, l], 'action', actionName, function (): void {
        addToList(true)
    })

    function onListClick() {
        track('List Click')
    }

    function handleAddToListClick() {
        addToList()
    }

    const ids = new Set()

    const fromUser =
        user &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        extractNodes(user.lists?.edges ?? []).map(function (list): ReactElement | null {
            const { name, discogsId, url } = list

            ids.add(discogsId)

            async function handleRemove(): Promise<void> {
                const yes = await confirm.ask()
                if (yes) {
                    await remove.perform({
                        listId: discogsId,
                        discogsId: props.discogsId,
                        itemType,
                    })
                }
            }

            return (
                <CuratedListsItem
                    key={discogsId}
                    name={name}
                    url={url}
                    username={user.username}
                    onRemove={handleRemove}
                    removing={remove.loading}
                    onClick={onListClick}
                />
            )
        })

    const lis = extractNodes(lists?.edges).map(function (list: ListInfoFragment): ReactElement | null {
        const { name, discogsId, url, curator } = list
        if (ids.has(discogsId)) {
            return null
        }

        return (
            <CuratedListsItem key={discogsId} name={name} url={url} username={curator.username} onClick={onListClick} />
        )
    })

    const id = 'curated-lists'

    const header = (
        <>
            <h2 className={css.title} tabIndex={0}>
                <Trans>Lists</Trans>
            </h2>
            <Button color='link' onClick={handleAddToListClick} className={css.add}>
                <Trans>Add to List</Trans>
            </Button>
        </>
    )

    if (!lists) {
        return (
            <Section id={id} collapsible={itemType !== 'ARTIST'} title={<Trans>Lists</Trans>}>
                <Loading height='3em' />
            </Section>
        )
    }

    return (
        <>
            <Section id={id} collapsible={itemType !== 'ARTIST'} header={header}>
                <ul className={css.simple}>
                    {fromUser}
                    {lis}
                </ul>
                {lists.edges.length === 0 && (
                    <Button color='link' onClick={handleAddToListClick}>
                        <Trans>Add to List</Trans>
                    </Button>
                )}
                {lists.edges.length > 0 && <ViewMore path='/lists' name='Lists' />}
                <Confirmation confirmText={<Trans>Remove Item</Trans>} {...confirm.props} danger>
                    <Trans>Are you sure you want to remove this item?</Trans>
                </Confirmation>
            </Section>
            {addItemToList.component}
        </>
    )
}

type CuratedListItemProps = {
    name: string
    url: string
    username: string
    onRemove?: () => void
    onClick?: () => void
    removing?: boolean
}

function CuratedListsItem(props: CuratedListItemProps): ReactElement {
    const { name, url, username, onRemove, removing, onClick } = props

    return (
        <li>
            <Trans>
                <Link href={url} onClick={onClick}>
                    {name}
                </Link>{' '}
                by <Link href={`/user/${username}`}>{username}</Link>
            </Trans>
            {onRemove && (
                <>
                    {' '}
                    <Button onClick={onRemove} className={css.remove} color='link' disabled={removing}>
                        <small>
                            (<Trans>remove</Trans>)
                        </small>
                    </Button>
                </>
            )}
        </li>
    )
}
