import { ReactElement } from 'react'
import classnames from 'classnames'
import { Spinner } from '../spinner'

import css from './styles.css'

type Props = {
    className?: string
    height?: number | string
}

export function Loading(props: Props): ReactElement {
    const { height = 0, className } = props

    return (
        <div style={{ minHeight: height }} className={classnames(css.loading, className)}>
            <Spinner />
        </div>
    )
}
