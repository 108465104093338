import { ReactElement, useRef } from 'react'
import classnames from 'classnames'
import { Trans } from '@lingui/macro'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Record } from '../record'
import { IdentifierType } from '../identifier'

import css from './styles.css'

type Props = {
    type: IdentifierType
    discogsId: number
    className?: string
}

export function ShortCode(props: Props): ReactElement {
    const { discogsId, type, className } = props

    const ref = useRef<HTMLSpanElement>(null)
    const id = 'shortcode-tooltip'

    function select(): void {
        if (!ref.current) {
            return
        }

        // @ts-expect-error: TypeScript does not know createTextRange
        if (document.body.createTextRange) {
            // @ts-expect-error: TypeScript does not know createTextRange
            const range = document.body.createTextRange()
            range.moveToElementText(ref.current)
            range.select()
            return
        }

        const selection = window.getSelection()
        const range = document.createRange()
        range.selectNodeContents(ref.current)
        selection?.removeAllRanges()
        selection?.addRange(range)
    }

    const pageType = {
        a: 'Artist',
        m: 'Master Release',
        r: 'Release',
        l: 'Label',
    }
    const copyText = `[${type}${discogsId}]`

    return (
        <button type='button' className={classnames(css.id, className)} onClick={select} aria-labelledby={id}>
            <Record />{' '}
            <CopyToClipboard text={copyText}>
                <span ref={ref}>{copyText}</span>
            </CopyToClipboard>
            <div className={css.tooltip} id={id}>
                <Trans>Copy {pageType[type as keyof typeof pageType]} Code</Trans>
            </div>
        </button>
    )
}
