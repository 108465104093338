import { ReactElement, ComponentType, useState } from 'react'
import { Trans, t } from '@lingui/macro'
import classnames from 'classnames'

import { useLocalisation } from '../../i18n'
import { Close } from '../icon'
import { useFlag } from '../../flags'

import css from './styles.css'

// These are the ad specifications:
//
//   `ad_top`
//   Top banner on deskop and mobile
//   desktop: 728 x 90
//   mobile: 320 x 50
//
//   `ad_right`
//   Under tracklist on mobile
//   desktop: n/a
//   mobile: 300 x 250
//
//   `ad_right_b`
//   Under statistics on desktop and mobile
//   desktop: 320 x 50
//   mobile: 320 x 50
//
//   `ad_flexa`
//   Under marketplace on desktop
//   desktop: 300 x 600
//   mobile: n/a
//
//   `ad_bottom`
//   Bottom sticky ad
//   desktop: 728 x 90
//   mobile: 320 x 50

type AdProps = {
    children?: ReactElement
    disableAdsScript?: boolean
}

const names = {
    ad_top: css.ad_top,
    ad_bottom: css.ad_bottom,
    ad_flexa: css.ad_flexa,
    ad_right: css.ad_right,
    ad_right_b: css.ad_right_b,
    ad_video: css.ad_video,
    ad_discography: css.ad_bottom,
    ad_discography_b: css.ad_bottom,
}

const classes = ['ad', 'display-ad', 'ads-here', 'ads-ad']

type AdName = keyof typeof names

type Options = {
    sticky?: boolean
}

function adSpace(name: AdName, options: Options = {}): ComponentType<AdProps> {
    const { sticky = false } = options

    return function Ad(props: AdProps): ReactElement | null {
        const { children, disableAdsScript = false } = props

        const { i18n } = useLocalisation()
        const [stick, setStick] = useState(sticky)

        const disabled = useFlag('disable_ads') || disableAdsScript

        if (disabled) {
            return null
        }

        function close(): void {
            setStick(false)
        }

        const classname = classnames(
            names[name],
            stick && css.sticky,
            sticky && stick && 'sticky_footer',
            sticky && !stick && 'sticky_footer_dismissed',
            ...classes,
        )

        return (
            <div className={classname} role='marquee' aria-label={t(i18n)`Advertisement`}>
                {sticky && stick && (
                    <button onClick={close} className={css.close} title={t(i18n)`Hide Ad`}>
                        <Close aria-hidden='true' />
                    </button>
                )}
                <div className={css.label}>
                    <Trans>Ad</Trans>
                </div>
                {children}
                <div id={name} className={css.inner} />
            </div>
        )
    }
}

export const AdTop = adSpace('ad_top')
export const AdFlexA = adSpace('ad_flexa')
export const AdRight = adSpace('ad_right')
export const AdRightB = adSpace('ad_right_b')
export const AdDiscography = adSpace('ad_discography')
export const AdDiscographyB = adSpace('ad_discography_b')
export const AdBottom = adSpace('ad_bottom', { sticky: true })
export const AdVideo = adSpace('ad_video')
