import { LabelHTMLAttributes, ReactElement } from 'react'
import classnames from 'classnames'
import { Trans } from '@lingui/macro'

import css from './styles.css'

type Props = {
    children: ReactElement | ReactElement[] | string
    optional?: boolean
    className?: string
} & LabelHTMLAttributes<HTMLLabelElement>

export function Label(props: Props): ReactElement {
    const { children, className, optional = false, ...rest } = props

    return (
        <label {...rest} className={classnames(css.label, className)}>
            {children}
            {optional && (
                <span className={css.optional}>
                    <Trans>Optional</Trans>
                </span>
            )}
        </label>
    )
}
