import { ReactElement } from 'react'
import classnames from 'classnames'
import { t, plural } from '@lingui/macro'

import { WithProps } from '../../add-state'
import { useLocalisation } from '../../i18n'
import { Star as StarFilled, StarOpen, Close } from '../icon'
import { Props, State, Action } from '.'

import css from './styles.css'

type UIProps = WithProps<Props, State, Action>

export function RatingsUI(props: UIProps): ReactElement {
    const { value, hovering, dispatch, onChange } = props
    const { i18n } = useLocalisation()

    const ariaLabel = value === 0 ? t(i18n)`not rated` : ''

    return (
        <div aria-label={ariaLabel}>
            <StarButton min={1} rating={hovering ?? value} dispatch={dispatch} onChange={onChange} />
            <StarButton min={2} rating={hovering ?? value} dispatch={dispatch} onChange={onChange} />
            <StarButton min={3} rating={hovering ?? value} dispatch={dispatch} onChange={onChange} />
            <StarButton min={4} rating={hovering ?? value} dispatch={dispatch} onChange={onChange} />
            <StarButton min={5} rating={hovering ?? value} dispatch={dispatch} onChange={onChange} />
            <button
                type='button'
                onClick={(): void => onChange(null)}
                className={css.remove}
                disabled={!value}
                aria-label={t(i18n)`Remove Rating`}
            >
                <Close aria-hidden='true' />
            </button>
        </div>
    )
}
type StarProps = {
    min: number
    rating: null | number
    dispatch: UIProps['dispatch']
    onChange: (value: number | null) => void
}

function StarButton(props: StarProps): ReactElement {
    const { min, rating, dispatch, onChange } = props
    const { i18n } = useLocalisation()
    const isRatingValue = rating === min

    return (
        <button
            onMouseEnter={(): void => dispatch({ type: 'hover', rating: min })}
            onMouseLeave={(): void => dispatch({ type: 'hover', rating: null })}
            onClick={(): void => onChange(min)}
            className={css.star}
            type='button'
            aria-pressed={isRatingValue ? isRatingValue : undefined}
            role={isRatingValue ? 'button' : undefined}
            title={t(i18n)`${plural(min, {
                one: 'Rate this release 1 star',
                other: 'Rate this release # stars',
            })}`}
        >
            <StarUI min={min} rating={rating ?? 0} />
        </button>
    )
}

type ValueProps = {
    username?: string
    value: number | null
    className?: string
}

export function RatingsValue(props: ValueProps): ReactElement {
    const { value, username, ...rest } = props
    const { i18n } = useLocalisation()

    const ariaLabel = `${username ? username : ''} ${
        value
            ? t(i18n)`${plural(value.toString(), {
                  one: 'rated this release 1 star',
                  other: 'rated this release # stars',
              })}`
            : t(i18n)`no rating`
    }`

    return (
        // non-interactive element shouldn't have aria-label, but it makes the most sense here
        <span role='note' {...rest} aria-label={ariaLabel}>
            <StarUI min={1} rating={value ?? 0} />
            <StarUI min={2} rating={value ?? 0} />
            <StarUI min={3} rating={value ?? 0} />
            <StarUI min={4} rating={value ?? 0} />
            <StarUI min={5} rating={value ?? 0} />
        </span>
    )
}

type StarUIProps = {
    rating: number
    min: number
}

function StarUI(props: StarUIProps): ReactElement {
    const { min, rating } = props

    const fill = rating >= min
    const Icon = fill ? StarFilled : StarOpen

    return <Icon aria-hidden='true' className={classnames(css.icon, fill && css.fill)} />
}
