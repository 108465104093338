import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'

import { Link } from '../../lib/components/link'
import { useInteraction } from '../../lib/components/analytics'

import css from './styles.css'

type Props = {
    name?: string
    path: string
}

export function ViewMore(props: Props): ReactElement {
    const { name, path } = props
    const track = useInteraction()

    function handleMoreClick() {
        track('View More Lists')
    }

    return (
        <Link href={path} className={css.more} onClick={handleMoreClick}>
            <Trans>View More {name}</Trans> →
        </Link>
    )
}
