import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import { useDebounce } from 'use-debounce'

import { useMarkupPreviewQuery } from '../../api/types'
import { Markup } from '../../lib/components/markup'

import css from './preview.css'

type PreviewProps = {
    value: string
}

export function Preview(props: PreviewProps): ReactElement | null {
    const { value } = props

    const [markup] = useDebounce(value, 300, {
        leading: true,
    })

    const { data, previousData } = useMarkupPreviewQuery({
        ssr: false,
        skip: value === '',
        variables: {
            markup,
        },
    })

    const html = (data ?? previousData)?.markup.html ?? ''
    if (value === '' || html === '') {
        return null
    }

    return (
        <div className={css.preview}>
            <strong>
                <Trans>Preview</Trans>
            </strong>
            <div className={css.content}>
                <Markup html={html} />
            </div>
        </div>
    )
}
