import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'

import { Link } from '../../lib/components/link'

import css from './no-items.css'
import { ExternalLink } from '../../lib/components/icon'

export function NoItemsAvailable(): ReactElement {
    return (
        <div className={css.noItems}>
            <p>
                <Trans>No items available in the Marketplace</Trans>
            </p>
            <p>
                <Trans>
                    <Link href='https://www.discogs.com/selling' target='_blank' rel='noreferrer noopener'>
                        Learn more about selling on Discogs
                        <ExternalLink className={css.external} aria-hidden='true' />
                    </Link>
                </Trans>
            </p>
        </div>
    )
}
