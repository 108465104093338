import { ReactElement, ReactNode } from 'react'
import { ErrorBoundary } from '../error-boundary'
import { Trans } from '@lingui/macro'

type ErrorSectionProps = {
    children: ReactNode | ReactNode[]
}

export function ErrorSection(props: ErrorSectionProps): ReactElement {
    return <ErrorBoundary fallback={Fallback} {...props} />
}

function Fallback(): ReactElement {
    return <Trans>Something went wrong in this section of the page.</Trans>
}
