import { ReactElement, TimeHTMLAttributes, useState } from 'react'
import { useLocalisation } from '../../i18n'
import { useIdle } from '../../use-idle'

type Props = { value: string } & TimeHTMLAttributes<HTMLTimeElement>

type Options = {
    year?: 'numeric'
    month?: 'short'
    day?: 'numeric'
}

const fulldate: Options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
}

const noday: Options = {
    year: 'numeric',
    month: 'short',
}

const nomonth: Options = {
    year: 'numeric',
}

const optionsByDashes = [nomonth, noday, fulldate]

function fix(date: string): string {
    return date
        .split('-')
        .map(function (s: string, i: number) {
            const len = i === 0 ? 4 : 2
            return s.padStart(len, '0')
        })
        .join('-')
        .replace(/(-00)?-00/, '')
}

function format(locale: string, date: Date, options: Options): string {
    return date.toLocaleDateString(locale, {
        timeZone: 'UTC',
        ...options,
    })
}

export function TruncatedDate(props: Props): ReactElement | null {
    const { value, ...rest } = props
    const locale = useRefineLocale()
    const fixed = fix(value)
    const date = new Date(fixed)
    const dashes = fixed.match(/-/g)?.length ?? 0
    const options = optionsByDashes[dashes] as Options | undefined
    if (!options) {
        return null
    }

    return (
        <time {...rest} dateTime={fixed}>
            {format(locale, date, options)}
        </time>
    )
}

type FullDateProps = { value: Date } & TimeHTMLAttributes<HTMLTimeElement>

export function FullDate(props: FullDateProps): ReactElement {
    const { value, ...rest } = props
    const locale = useRefineLocale()

    return (
        <time {...rest} dateTime={value.toISOString().replace(/T.*$/, '')}>
            {format(locale, value, fulldate)}
        </time>
    )
}

// Force the locale to adhere to the localisation locale on the server (eg. 'en')
// but refine to the user's navigator setting in the browser (eg. 'en-GB').
// Prevents hydration errors.
function useRefineLocale(): string {
    const { language } = useLocalisation()
    const [locale, setLocale] = useState<string>(language)
    useIdle(function (): void {
        if (navigator.language.startsWith(language)) {
            setLocale(navigator.language)
        }
    })

    return locale
}
