import { ReactElement, useRef } from 'react'
import { Trans, t, Plural } from '@lingui/macro'

import { ItemType, TopLevelReviewFragment, ReviewFragment } from '../../api/types'

import { useLocalisation } from '../../lib/i18n'
import { useClickOutside } from '../../lib/use-click-outside'
import { addState, WithProps } from '../../lib/add-state'
import { useLogin, login } from '../../lib/login'

import { FullDate } from '../../lib/components/date'
import { Button } from '../../lib/components/buttons'
import { RatingsValue } from '../../lib/components/ratings'
import { Link } from '../../lib/components/link'
import { MailReply, Tag, Eye, EyeSlash, Ban, User, CaretDown, Trash, Edit } from '../../lib/components/icon'
import { Spinner } from '../../lib/components/spinner'
import { Confirmation, useConfirm } from '../../lib/components/modal'
import { Markup } from '../../lib/components/markup'
import { useInteraction } from '../../lib/components/analytics'

import { useRemoveReviewMutation, useToggleFoundReviewHelpfulMutation } from './mutations'
import { Editor } from './editor'
import css from './review.css'
import { Referencing } from './referencing'

type ReviewProps = {
    review: TopLevelReviewFragment | ReviewFragment
    keyReleaseId?: number
    discogsId: number
    parent?: TopLevelReviewFragment
    itemType: ItemType
}

type State = {
    dropdownOpen: boolean
    showingReplies: boolean
    isReplying: boolean
    isEditing: boolean
}

type Action =
    | { type: 'open-dropdown' }
    | { type: 'close-dropdown' }
    | { type: 'show-replies' }
    | { type: 'hide-replies' }
    | { type: 'reply' }
    | { type: 'cancel-reply' }
    | { type: 'edit' }
    | { type: 'cancel-edit' }

type ReviewUIProps = WithProps<ReviewProps, State, Action>

function reducer(props: ReviewProps, state: State, action: Action): State {
    switch (action.type) {
        case 'open-dropdown':
            return { ...state, dropdownOpen: true }
        case 'close-dropdown':
            return { ...state, dropdownOpen: false }
        case 'show-replies':
            return { ...state, showingReplies: true }
        case 'hide-replies':
            return { ...state, showingReplies: false }
        case 'reply':
            return { ...state, isReplying: true }
        case 'cancel-reply':
            return { ...state, isReplying: false }
        case 'edit':
            return { ...state, isEditing: true, dropdownOpen: false }
        case 'cancel-edit':
            return { ...state, isEditing: false, dropdownOpen: false }
        default:
            return state
    }
}

export const SingleReview = addState(SingleReviewUI, reducer, {
    dropdownOpen: false,
    showingReplies: false,
    isReplying: false,
    isEditing: false,
})

export function SingleReviewUI(props: ReviewUIProps): ReactElement {
    const {
        review,
        dispatch,
        dropdownOpen,
        showingReplies,
        isReplying,
        isEditing,
        keyReleaseId,
        discogsId,
        parent,
        itemType,
    } = props

    const {
        discogsId: reviewId,
        reviewer: { discogsId: authorId, username, avatarUrl },
        created: screated,
        modified: smodified,
        content,
        foundHelpful,
        reviewItem,
        rating,
    } = review

    const replies = 'replies' in review ? review.replies : []

    const { i18n } = useLocalisation()
    const { loggedIn, user } = useLogin()
    const confirm = useConfirm()
    const track = useInteraction()

    const [remove] = useRemoveReviewMutation({
        discogsId,
        discogsReviewId: reviewId,
        parent,
        itemType,
    })

    const created = new Date(screated)
    const modified = new Date(smodified)
    const userLink = `/user/${username}`

    async function confirmAndRemove(): Promise<void> {
        const result = await confirm.ask()
        if (!result) {
            return
        }

        await remove()
    }

    function handleReply(): void {
        if (loggedIn) {
            dispatch(isReplying ? { type: 'cancel-reply' } : { type: 'reply' })
            return
        }

        login()
    }

    function handleCancelReply(): void {
        dispatch({ type: 'cancel-reply' })
    }

    function handleToggleReplies(): void {
        track('See Review Reply')
        dispatch(showingReplies ? { type: 'hide-replies' } : { type: 'show-replies' })
    }

    if (isEditing) {
        return (
            <div className={css.review} id={`#review-${reviewId}`}>
                <Editor
                    keyReleaseId={keyReleaseId}
                    discogsId={discogsId}
                    reviewId={reviewId}
                    isEdit
                    initialValue={content.markup}
                    onCancel={(): void => dispatch({ type: 'cancel-edit' })}
                    itemType={itemType}
                />
            </div>
        )
    }
    return (
        <>
            <Confirmation {...confirm.props} confirmText={<Trans>Delete Review</Trans>}>
                <Trans>Do you really want to remove this review?</Trans>
            </Confirmation>
            <li>
                <div className={css.review} id={`#review-${reviewId}`}>
                    <Link href={userLink} className={css.pic}>
                        {avatarUrl ? (
                            <img src={avatarUrl} alt={t(i18n)`${username}'s avatar`} width={50} height={50} />
                        ) : (
                            <User />
                        )}
                    </Link>
                    <div className={css.content}>
                        <div className={css.header}>
                            <Link href={userLink} className={css.username}>
                                {username}
                            </Link>{' '}
                            <FullDate value={created} className={css.created} />
                            <Dropdown
                                reviewId={reviewId}
                                open={dropdownOpen}
                                onOpen={(): void => dispatch({ type: 'open-dropdown' })}
                                onClose={(): void => dispatch({ type: 'close-dropdown' })}
                                isAuthor={user ? authorId === user.discogsId : false}
                                onRemove={confirmAndRemove}
                                onEdit={(): void => dispatch({ type: 'edit' })}
                                onReport={() => track('Report Review')}
                            />
                        </div>
                        {modified.getTime() !== created.getTime() && <div className={css.edited}>{when(modified)}</div>}
                        {!parent && rating && (
                            <RatingsValue username={username} value={rating.value} className={css.rating} />
                        )}
                        {itemType === ItemType.MasterRelease && <Referencing reviewItem={reviewItem} />}
                        <Markup html={content.html} />
                        <div>
                            {!parent && (
                                <Button type='button' color='link' className={css.button} onClick={handleReply}>
                                    <MailReply aria-hidden='true' />
                                    <Trans>Reply</Trans>
                                </Button>
                            )}
                            {replies.length > 0 && (
                                <Button type='button' color='link' className={css.button} onClick={handleToggleReplies}>
                                    {showingReplies ? (
                                        <>
                                            <EyeSlash aria-hidden='true' />
                                            <Trans>Hide replies</Trans>
                                        </>
                                    ) : (
                                        <>
                                            <Eye aria-hidden='true' />
                                            <Plural value={replies.length} one='See # reply' other='See # replies' />
                                        </>
                                    )}
                                </Button>
                            )}
                            <HelpfulButton reviewId={reviewId} foundHelpful={foundHelpful} authorId={authorId} />
                        </div>
                    </div>
                </div>
                <div className={css.replies}>
                    {isReplying && (
                        <Editor
                            parent={props.review as TopLevelReviewFragment}
                            keyReleaseId={keyReleaseId}
                            discogsId={discogsId}
                            onCancel={handleCancelReply}
                            itemType={itemType}
                        />
                    )}
                    <ul>
                        {showingReplies &&
                            replies.map(
                                (reply: ReviewFragment): ReactElement => (
                                    <SingleReview
                                        {...props}
                                        review={reply}
                                        key={reply.discogsId}
                                        parent={review as TopLevelReviewFragment}
                                        keyReleaseId={keyReleaseId}
                                        itemType={itemType}
                                    />
                                ),
                            )}
                    </ul>
                </div>
            </li>
        </>
    )
}
export function when(date: Date): ReactElement {
    const diff = Math.floor((Date.now() - date.getTime()) / 1000)

    const years = Math.floor(diff / 31536000)
    const months = Math.floor(diff / 2592000)
    const days = Math.floor(diff / 86400)
    const hours = Math.floor(diff / 3600)
    const minutes = Math.floor(diff / 60)

    if (years >= 1) {
        return <Plural value={years} one='Edited one year ago' other='Edited # years ago' />
    }
    if (months >= 1) {
        return <Plural value={months} one='Edited one month ago' other='Edited # months ago' />
    }
    if (days >= 1) {
        return <Plural value={days} one='Edited one day ago' other='Edited # days ago' />
    }
    if (hours >= 1) {
        return <Plural value={hours} one='Edited one hour ago' other='Edited # hours ago' />
    }
    if (minutes >= 1) {
        return <Plural value={minutes} one='Edited one minute ago' other='Edited # minutes ago' />
    }

    return <Trans>Edited just now</Trans>
}

type DropdownProps = {
    reviewId: number
    open: boolean
    onOpen: () => void
    onClose: () => void
    onRemove: () => void
    onEdit: () => void
    isAuthor: boolean
    onReport: () => void
}

function Dropdown(props: DropdownProps): ReactElement {
    const { reviewId, open, onOpen, onClose, isAuthor, onRemove, onEdit, onReport } = props

    const { i18n } = useLocalisation()
    const button = `actions-${reviewId}`
    const menu = `menu-${reviewId}`
    const ref = useRef<HTMLDivElement>(null)
    useClickOutside(ref, onClose)

    function toggleOpen(): void {
        if (open) {
            onClose()
        } else {
            onOpen()
        }
    }

    return (
        <div className={css.dropdown} ref={ref}>
            <button
                type='button'
                onClick={toggleOpen}
                title={t(i18n)`Actions`}
                id={button}
                aria-haspopup='true'
                aria-controls={menu}
                aria-expanded={open}
            >
                <CaretDown aria-hidden='true' />
            </button>
            <ul hidden={!open} id={menu} role='menu' aria-labelledby={button}>
                {isAuthor && (
                    <li role='menuitem'>
                        <Button onClick={onEdit} color='link'>
                            <Edit aria-hidden='true' />
                            <Trans>Edit</Trans>
                        </Button>
                    </li>
                )}
                <li role='menuitem'>
                    <Link href={`/reviews/report/${reviewId}`} onClick={onReport}>
                        <Ban aria-hidden='true' />
                        <Trans>Report</Trans>
                    </Link>
                </li>
                {isAuthor && (
                    <li role='menuitem'>
                        <Button className={css.deleteReview} color='link' onClick={onRemove}>
                            <Trash aria-hidden='true' />
                            <Trans>Delete</Trans>
                        </Button>
                    </li>
                )}
            </ul>
        </div>
    )
}

type HelpfulProps = {
    reviewId: number
    foundHelpful: number
    authorId?: number
}

function HelpfulButton(props: HelpfulProps): ReactElement {
    const { reviewId, foundHelpful, authorId } = props

    const { loggedIn, user } = useLogin()
    const [toggle, { loading }] = useToggleFoundReviewHelpfulMutation({
        discogsReviewId: reviewId,
    })

    async function handleHelpful(): Promise<void> {
        if (!loggedIn) {
            login()
            return
        }

        await toggle()
    }

    const icon = loading ? <Spinner aria-hidden='true' /> : <Tag aria-hidden='true' />

    return (
        <Button
            type='button'
            color='link'
            className={css.button}
            onClick={handleHelpful}
            disabled={authorId === user?.discogsId}
        >
            {icon}
            {foundHelpful > 0 && `${foundHelpful} `}
            <Trans>Helpful</Trans>
        </Button>
    )
}
