import { ReactElement } from 'react'
import { Trans, t } from '@lingui/macro'
import { AnchorButton } from '@discogs/amped/button'

import { ItemType, ReleaseMarketplaceStatsFragment, UserReleaseInventoryFragment } from '../../api/types'

import { useLocalisation } from '../../lib/i18n'
import { Link } from '../../lib/components/link'
import { Section } from '../../lib/components/section'
import { Format, asFormat } from '../../lib/format'
import { ForSale } from '../../lib/components/for-sale'
import { ExternalLink } from '../../lib/components/icon'
import { useLogin } from '../../lib/login'
import { useInteraction } from '../../lib/components/analytics'
import { NoItemsAvailable } from './no-items'

import css from './styles.css'

const sellText = {
    [Format.CD]: <Trans>Sell CD</Trans>,
    [Format.CDr]: <Trans>Sell CDr</Trans>,
    [Format.DVD]: <Trans>Sell DVD</Trans>,
    [Format.Cassette]: <Trans>Sell Cassette</Trans>,
    [Format.File]: <Trans>Sell File</Trans>,
    [Format.Vinyl]: <Trans>Sell Vinyl</Trans>,
    [Format.BoxSet]: <Trans>Sell Box Set</Trans>,
    [Format.BluRay]: <Trans>Sell Blu-ray</Trans>,
    [Format.VHS]: <Trans>Sell VHS</Trans>,
    [Format.ReelToReel]: <Trans>Sell Reel-To-Reel</Trans>,
    [Format.UMatic]: <Trans>Sell U-Matic</Trans>,
    [Format.BetacamSP]: <Trans>Sell Betacam-SP</Trans>,
    [Format.AllMedia]: <Trans>Sell All Media</Trans>,
    [Format.Unknown]: <Trans>Sell a Copy</Trans>,
}

type Props = ReleaseMarketplaceStatsFragment &
    Partial<UserReleaseInventoryFragment> & {
        physicalFormats?: boolean
        itemType: ItemType
    }

export function MarketplaceStatsUI(props: Props): ReactElement {
    const { i18n } = useLocalisation()
    const {
        discogsId,
        blockedFromSale,
        listings,
        formats,
        lowestPrice,
        myListings,
        itemType,
        physicalFormats = true,
    } = props
    const { user } = useLogin()
    const track = useInteraction()

    const { totalCount = 0 } = listings ?? {}
    const format = asFormat(formats?.[0]?.name)

    const itemTypeChecker = {
        [ItemType.MasterRelease]: {
            id: 'master-release-marketplace',
            digital: false,
            buyUrl: `/sell/list?master_id=${discogsId}`,
            sellUrl: `/sell`,
            buyButtonText: <Trans>Shop now</Trans>,
            sellButtonText: <Trans>Sell a copy</Trans>,
        },
        [ItemType.Release]: {
            id: 'release-marketplace',
            digital: true,
            buyUrl: `/sell/release/${discogsId}`,
            sellUrl: `/sell/post/${discogsId}`,
            buyButtonText: <Trans>Shop now</Trans>,
            sellButtonText: sellText[format],
        },
        [ItemType.Artist]: {
            id: 'artist-marketplace',
            digital: true,
            buyUrl: `/sell/list?artist_id=${discogsId}`,
            sellUrl: `/sell`,
            buyButtonText: <Trans>Shop now</Trans>,
            sellButtonText: <Trans>Sell a copy</Trans>,
        },
        [ItemType.Label]: {
            id: 'label-marketplace',
            digital: true,
            buyUrl: `/sell/list?label_id=${discogsId}`,
            sellUrl: `/sell`,
            buyButtonText: <Trans>Shop now</Trans>,
            sellButtonText: <Trans>Sell a copy</Trans>,
        },
    }

    const { digital, buyUrl, sellUrl, buyButtonText, id } = itemTypeChecker[itemType]

    if (blockedFromSale) {
        return (
            <Section title={<Trans>For sale on Discogs</Trans>} id='release-marketplace'>
                <p className={css.paragraph}>
                    <Trans>
                        This release is blocked from sale on Discogs. It is not permitted to sell this item in our
                        Marketplace.
                    </Trans>
                </p>
                <p>
                    <Link href='https://support.discogs.com/hc/articles/4402686008589'>
                        <Trans>Learn more about blocked items on Discogs</Trans>
                        <ExternalLink className={css.external} aria-hidden='true' />
                    </Link>
                </p>
            </Section>
        )
    }

    if (digital && formats?.length === 1 && format === 'File') {
        return (
            <Section title={<Trans>For sale on Discogs</Trans>} id='release-marketplace' collapsible={false}>
                <p>
                    <Trans>Digital content is not available for sale or trade.</Trans>
                </p>
                <Link href='https://www.discogs.com/selling' target='_blank' rel='noreferrer noopener'>
                    <Trans>Learn more about selling on Discogs</Trans>
                    <ExternalLink className={css.external} aria-hidden='true' />
                </Link>
            </Section>
        )
    }

    // Handle Master-releases that have no physical edition
    if (!physicalFormats) {
        return (
            <Section title={<Trans>For sale on Discogs</Trans>} id='release-marketplace' collapsible={false}>
                <p>
                    <Trans>Digital content is not available for sale or trade.</Trans>
                </p>
            </Section>
        )
    }

    function handleBuy(): void {
        track('Buy Item')
    }

    function handleSell(): void {
        track('Sell Item')
    }

    const header = (
        <div className={css.header}>
            <h2>
                <Trans>For sale on Discogs</Trans>
            </h2>
            <Link
                aria-label={t(i18n)`Sell a Copy`}
                title={t(i18n)`Sell a Copy`}
                href={sellUrl}
                onClick={handleSell}
                className={css.sellLink}
            >
                Sell a copy
            </Link>
        </div>
    )

    const sellinfo = (
        <div className={css.mpData}>
            <h2>
                {(myListings?.totalCount ?? 0) > 0 && user && (
                    <Link
                        aria-label={t(i18n)`${myListings?.totalCount} in Inventory`}
                        title={t(i18n)`${myListings?.totalCount} in Inventory`}
                        href={`${buyUrl}?seller=${user.username}`}
                        className={css.skittle}
                    >
                        {myListings?.totalCount.toLocaleString()}
                    </Link>
                )}
            </h2>

            {totalCount > 0 && (
                <ForSale
                    buyUrl={buyUrl}
                    lowestPrice={lowestPrice ? lowestPrice : undefined}
                    copiesForSale={totalCount}
                    onClick={handleBuy}
                />
            )}
        </div>
    )

    return (
        <Section header={header} id={id} collapsible={false}>
            {totalCount <= 0 && <NoItemsAvailable />}
            {sellinfo}
            <div className={css.buttons}>
                {totalCount > 0 && (
                    <AnchorButton href={buyUrl} variant='primary' dense onClick={handleBuy} className='ld-shop-now'>
                        {buyButtonText}
                    </AnchorButton>
                )}
            </div>
        </Section>
    )
}
