import { ReactElement } from 'react'
import { HTTPHeader } from '../http-link'

export enum IdentifierType {
    Release = 'r',
    Master = 'm',
    Artist = 'a',
    Label = 'l',
}

type Props = {
    type: IdentifierType
    discogsId: number
}

export function Identifier(props: Props): ReactElement {
    const { type, discogsId } = props
    return <HTTPHeader name='x-discogs-identifiers' value={identifier(type, discogsId)} />
}

export function identifier(type: IdentifierType, discogsId: number): string {
    return `${type}${discogsId}`
}
