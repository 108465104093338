import { InputHTMLAttributes, ReactElement } from 'react'
import classnames from 'classnames'

import css from './styles.css'

type Props = InputHTMLAttributes<HTMLInputElement> & { className?: string }

export function Input(props: Props): ReactElement {
    return <input {...props} className={classnames(css.input, props.className)} />
}
