import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'

import { ItemType, ArtistReviewsFragment, ReleaseReviewsFragment, LabelReviewsFragment } from '../../api/types'

import { Section } from '../../lib/components/section'
import { Button } from '../../lib/components/buttons'
import { Spinner } from '../../lib/components/spinner'
import { Loading } from '../../lib/components/loading'
import { useInteraction } from '../../lib/components/analytics'
import { useLogin, login } from '../../lib/login'

import { Editor } from './editor'
import { SingleReview } from './review'
import { mutations } from './mutations'

import css from './styles.css'

const id = 'release-reviews'

type AsyncProps = {
    discogsId: number
    keyReleaseId?: number
    reviews?: ReleaseReviewsFragment['reviews'] | ArtistReviewsFragment['reviews'] | LabelReviewsFragment['reviews']
    itemType: ItemType
}

export type Props = {
    discogsId: number
    keyReleaseId?: number
    reviews: ReleaseReviewsFragment['reviews'] | ArtistReviewsFragment['reviews'] | LabelReviewsFragment['reviews']
    itemType: ItemType
}

export function ReleaseReviews(props: AsyncProps): ReactElement {
    const { reviews } = props

    if (!reviews) {
        return (
            <Section id={id} title={<Trans>Reviews</Trans>}>
                <Loading height={200} />
            </Section>
        )
    }
    return <ReleaseReviewsRender {...props} reviews={reviews} />
}

export function ReleaseReviewsRender(props: Props): ReactElement {
    const {
        discogsId,
        keyReleaseId,
        reviews: { totalCount, edges },
        itemType,
    } = props

    const { useMoreLazyQuery } = mutations[itemType]
    const [loadMore, { loading }] = useMoreLazyQuery()
    const { loggedIn } = useLogin()
    const track = useInteraction()

    function handleLoadMore(): void {
        const { cursor } = edges[edges.length - 1]
        void loadMore({
            variables: {
                discogsId,
                after: cursor,
            },
        })
    }

    function handleAddReview(): void {
        track('Add Review')
        login()
    }

    const header = (
        <div className={css.head}>
            <h2>
                <Trans>Reviews</Trans>
            </h2>
        </div>
    )

    const editor = loggedIn ? (
        <Editor keyReleaseId={keyReleaseId} discogsId={discogsId} itemType={itemType} />
    ) : (
        <Button onClick={handleAddReview} className={css.add}>
            <Trans>Add Review</Trans>
        </Button>
    )

    return (
        <Section header={header} id={id}>
            {editor}
            <ul>
                {edges.map(
                    (edge: Props['reviews']['edges'][0]): ReactElement | undefined =>
                        edge.node && (
                            <SingleReview
                                review={edge.node}
                                key={edge.node.discogsId}
                                keyReleaseId={keyReleaseId}
                                discogsId={discogsId}
                                itemType={itemType}
                            />
                        ),
                )}
            </ul>
            {edges.length < totalCount && (
                <Button color='link' onClick={handleLoadMore} disabled={loading}>
                    <Trans>Load More Reviews</Trans>
                </Button>
            )}
            {loading && <Spinner className={css.spinner} />}
        </Section>
    )
}
