import { ReactElement } from 'react'
import classnames from 'classnames'

import css from './styles.css'

type Props = {
    className?: string
}

export function Record(props: Props): ReactElement {
    const { className } = props

    return (
        <svg role='img' aria-hidden='true' viewBox='0 0 100 100' className={classnames(css.vinyl, className)}>
            <circle cx='50' cy='50' r='33' fill='none' stroke='black' strokeWidth='33' />
            <circle cx='50' cy='50' r='4' />
        </svg>
    )
}
